import 'babel-polyfill';
import '../lib/scss/common.scss';
// 导入videojs 的样式
import 'video.js/dist/video-js.css';
import {useEffect} from "react";
import {timeout_fetch} from "$LIB/SportRequest";
import EventData from "$LIB/vendor/data/EventData";
import HostConfig from '$LIB/Host.config';
import { RouteFilter } from '$LIB/RouteFilter'; //路由(登入態)過濾
import { storeWrapper } from '../lib/redux/store';
import { Provider, useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'  //用於加載持久化redux數據，加載完才render
import {ACTION_UserInfo_logout} from "../lib/redux/actions/UserInfoAction";
import {ACTION_MaintainStatus_Logout} from "../lib/redux/actions/MaintainStatusAction";
import { useRouter } from 'next/router'

function MyApp({ Component, pageProps }) {
	const store = useStore((state)=>state);
	const router = useRouter()

	global.userInfo_logout = () => {
		store.dispatch(ACTION_UserInfo_logout())
		store.dispatch(ACTION_MaintainStatus_Logout())
	}

	//等同didmount 只會調用一次
	useEffect(() => {
		//console.log('===app didmount path:',router.pathname);
		//清理下載app配置，按需求：刷新後要可以重新展示出來
		sessionStorage.removeItem('appdownload-closed');

		const defaultCachePromise = new Promise(resolve => null);

		const sports = ['im','bti','saba'];
		const sportPathMaps = {'/sports-im': 'im', '/sports-bti': 'bti', '/sports-saba' : 'saba'};

		global.initialCache = {};
		sports.map(s => {
			global.initialCache[s] = {isUsed: false, isUsedForHeader: false, cachePromise: defaultCachePromise};
		})

		if (router.pathname
			&& sportPathMaps[router.pathname.toLowerCase()] //只有體育頁才獲取緩存數據
		)
		{
			const thisSport = sportPathMaps[router.pathname.toLowerCase()];
			const otherSports = sports.filter(s => s !== thisSport);

			//獲取緩存服務器數據
			const getSportsCache = (sportName) => {
				global.initialCache[sportName].cachePromise = timeout_fetch(
					fetch(HostConfig.Config.CacheApi + '/cache/v2/' + sportName)
					,3000 //最多查3秒，超過放棄
				)
					.then(response => response.json())
					.then(jsonData => {
						let newData = {};
						newData.trCount = jsonData.trCount;
						newData.count = jsonData.count;
						newData.list = jsonData.list.map(ev => EventData.clone(ev)); //需要轉換一下

						console.log('===get initialCache of',sportName,newData);

						return newData;
					})
					.catch(() => null)
				return global.initialCache[sportName].cachePromise;
			}

			//先獲取當前體育
			getSportsCache(thisSport)
				.finally(() => {
					//等當前體育獲取到，才獲取其他體育
					otherSports.map(oSport => {
						getSportsCache(oSport)
					})
				})
		}
	}, []);

	return (
		<RouteFilter>
			<Provider store={store}>
				{ //用typeof window !== "undefined" 判斷是 用戶瀏覽器(需要PersistGate) 或是 export(不用PersistGate)
					typeof window !== "undefined"
						? (
						<PersistGate persistor={store.__persistor} loading={null}>
							<Component {...pageProps} />
						</PersistGate>
					) : (
						<Component {...pageProps} />
					)
				}
			</Provider>
		</RouteFilter>
	);
}

export default storeWrapper.withRedux(MyApp);
