import {initialState} from "../reducers/UserInfoReducer";
import { fetchRequest } from '$LIB/SportRequest';
import { ApiPort } from '$LIB/SPORTAPI';

export const ACTION_USERINFO_UPDATE = 'ACTION_USERINFO_UPDATE';

//用戶登入
export const ACTION_UserInfo_login = (username) => {
  const payload = Object.assign(initialState,
    {
    isLogin: true,
    username: username,
  });

  const action = {
    type: ACTION_USERINFO_UPDATE,
    payload: payload,
  };

  return action;
}

//用戶登出
export const ACTION_UserInfo_logout = () => {
  const action = {
    type: ACTION_USERINFO_UPDATE,
    payload: initialState,
  };

  return action;
}

export const ACTION_UserInfo_updateBalanceSB = (newBalanceSB) => {
  const action = {
    type: ACTION_USERINFO_UPDATE,
    payload: {balanceSB: newBalanceSB},
  };

  return action;
}

//查詢餘額
export const ACTION_UserInfo_getBalanceSB = (forceUpdate = false) => {
  return (dispatch, getState) => {
    if (!getState().userInfo.isLogin) return Promise.resolve(); //沒登入不用處理

    //10秒節流，避免短時間頻繁調用
    if (global._getBalanceSB_throttle_handle && !forceUpdate) {
      //console.log('===太頻繁...跳過getBalanceSB...')
      return Promise.resolve();
    }

    if (!forceUpdate) {
      global._getBalanceSB_throttle_handle = setTimeout(function () {
        clearTimeout(global._getBalanceSB_throttle_handle);
        global._getBalanceSB_throttle_handle = null;
        //console.log('===clear getBalanceSB handle', JSON.stringify(global._getBalanceSB_throttle_handle));
      }, 10 * 1000); //10秒節流
    }

    const updateGettingBalance = {
      type: ACTION_USERINFO_UPDATE,
      payload: {isGettingBalance: true},
    };

    dispatch(updateGettingBalance);

    return fetchRequest(ApiPort.GETBalanceSB, 'GET').then((data) => {

      let payload = {
        balanceSB: 0,
        isGettingBalance: false,
      }

      data.map(item => { //查單個，會返回兩個？第一個還是null？神API
        if (item && item.name === 'SB') {
          payload.balanceSB = item.balance;
        }
      });

      const action = {
        type: ACTION_USERINFO_UPDATE,
        payload: payload,
      };

      return dispatch(action);
    }).catch(error => {
      const updateGettingBalance = {
        type: ACTION_USERINFO_UPDATE,
        payload: {isGettingBalance: false},
      };
      return dispatch(updateGettingBalance);
    });
  }
}

//查詢自我限制配置
export const ACTION_UserInfo_getSelfExclusion = (forceUpdate = false) => {
  return (dispatch, getState) => {
    if (!getState().userInfo.isLogin) return Promise.resolve(); //沒登入不用處理

    //10分節流，避免短時間頻繁調用
    if (global._getSelfExclusion_throttle_handle && !forceUpdate) {
      //console.log('===太頻繁...跳過getSelfExclusion...')
      return Promise.resolve();
    }

    if (!forceUpdate) {
      global._getSelfExclusion_throttle_handle = setTimeout(function () {
        clearTimeout(global._getSelfExclusion_throttle_handle);
        global._getSelfExclusion_throttle_handle = null;
        //console.log('===clear getSelfExclusion handle', JSON.stringify(global._getSelfExclusion_throttle_handle));
      }, 10 * 1000 * 600); //10分節流
    }

    return fetchRequest(ApiPort.GetSelfExclusion, 'GET').then((data) => {

      let payload = { selfExclusionInfo: {} };
      Object.assign(payload.selfExclusionInfo, initialState.selfExclusionInfo);

      //自我限制
      // selfExclusionInfo: {
      //   Status: false, //總開關
      //   DisableDeposit: false, //存款
      //   DisableFundIn: false,  //轉帳
      //   DisableBetting: false, //投注
      //   SelfExcludeSetDate: '2022-01-01T00:00:00.00',
      //   SelfExcludeExpiredDate: '2022-01-01T00:00:00.00',
      //   SelfExcludeDuration: 0,
      //   SelfExclusionSettingID: 0, //1:7天,2:90天,3:永久
      // },

      if (data.result) {
        if (data.result.hasOwnProperty('Status')) {
          payload.selfExclusionInfo.Status = data.result.Status;
        }
        if (data.result.hasOwnProperty('DisableDeposit')) {
          payload.selfExclusionInfo.DisableDeposit = data.result.DisableDeposit;
        }
        if (data.result.hasOwnProperty('DisableFundIn')) {
          payload.selfExclusionInfo.DisableFundIn = data.result.DisableFundIn;
        }
        if (data.result.hasOwnProperty('DisableBetting')) {
          payload.selfExclusionInfo.DisableBetting = data.result.DisableBetting;
        }
        if (data.result.hasOwnProperty('SelfExcludeSetDate')) {
          payload.selfExclusionInfo.SelfExcludeSetDate = data.result.SelfExcludeSetDate;
        }
        if (data.result.hasOwnProperty('SelfExcludeExpiredDate')) {
          payload.selfExclusionInfo.SelfExcludeExpiredDate = data.result.SelfExcludeExpiredDate;
        }
        if (data.result.hasOwnProperty('SelfExcludeDuration')) {
          payload.selfExclusionInfo.SelfExcludeDuration = data.result.SelfExcludeDuration;
        }
        if (data.result.hasOwnProperty('SelfExclusionSettingID')) {
          payload.selfExclusionInfo.SelfExclusionSettingID = data.result.SelfExclusionSettingID;
        }
      }

      const action = {
        type: ACTION_USERINFO_UPDATE,
        payload: payload,
      };

      return dispatch(action);
    }).catch(error => {
      console.log('/api/Member/GetSelfExclusionRestriction ERROR:',error);
    });
  }
}
