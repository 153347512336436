import {ACTION_USERINFO_UPDATE} from '../actions/UserInfoAction';

//用戶全域數據
export const initialState = {
  //是否已登入
  isLogin: false,
  //用戶名
  username: '',
  //SB餘額
  balanceSB: 0,
  //是否正在刷新餘額
  isGettingBalance: false,
  //自我限制
  selfExclusionInfo: {
    Status: false, //總開關
    DisableDeposit: false, //存款
    DisableFundIn: false,  //轉帳
    DisableBetting: false, //投注
    SelfExcludeSetDate: '2022-01-01T00:00:00.00',
    SelfExcludeExpiredDate: '2022-01-01T00:00:00.00',
    SelfExcludeDuration: 0,
    SelfExclusionSettingID: 0, //1:7天,2:90天,3:永久
  },
};

const UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_USERINFO_UPDATE: //更新數據
      //console.log('===userinfo update to : ', action.payload);
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default UserInfoReducer;
