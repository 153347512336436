import moment from 'moment';

let Config = {};
/* 测试环境Domain */
if (typeof global.location !== 'undefined') {
	/* 线上测试环境和 */
	let StagingApi = Boolean(
		[
			'sportsstaging.fun88.biz',
			'sports1staging.fun88.biz',
			'sports2staging.fun88.biz',
			'sports3staging.fun88.biz',
			'sports4staging.fun88.biz',
			'sports5staging.fun88.biz',
			'sports11staging.fun88.biz',
			'test1.letiyu88.com',
			'test2.letiyu88.com',
			'test3.letiyu88.com',
			'test4.letiyu88.com',
			'test5.letiyu88.com',
			'localhost:6868',
			'localhost:8080',
			'192.168.0.108:8080',
			'192.168.50.106:8080',
			'192.168.137.1:6868',
			'192.168.0.108:6868',
			'192.168.50.106:6868',
			'127.0.0.1:6868',
			'member.staging8p4.fun88.biz:6868'
		].find((v) => global.location.href.includes(v))
	);
	let localhostStagingApi = Boolean(
		[
			'localhost:6868',
			'localhost:8080',
			'192.168.0.108:8080',
			'192.168.50.106:8080',
			'192.168.137.1:6868',
			'192.168.0.108:6868',
			'192.168.50.106:6868',
			'127.0.0.1:6868',
			'member.staging8p4.fun88.biz:6868'
		].find((v) => global.location.href.includes(v))
	);
	/* 测试环境 */
	if (StagingApi) {
		//特例處理gateway api
		let gatewayAPI = localhostStagingApi ? 'http://sports2staging.fun88.biz' : '';
		Config = {
			HostApi: gatewayAPI /* 默认指向域名 */,
			defaultSport: 'im', //默認體育頁
			isLIVE: false, //判斷在 測試/開發 還是線上環境
			DefaultDomain: 'http://sportsstaging.fun88.biz',
			LocalHost: 'http://sportsstaging.fun88.biz/',
			//----mainsite app下載鏈接---
			MainSiteAppDownloadUrl: 'https://www.fun88asia.com/CN/download/app.htm',
			//-----推送配置-----
			SignalRUrl: 'https://gatewaystagingfun88wsnotification1.gamealiyun.com',
			SportImageUrl: 'https://simg.letiyu88.com', //聯賽隊伍圖 域名
			//-----緩存API配置-----
			CacheApi: 'https://sapi.letiyu88.com', //測試環境
			//CacheApi: 'https://sapi.leyouxi211.com', //線上域名
			//-----IM配置------
			IMAccessCode: 'ca9fb2a3e194173a', //fun測試
			IMApi: 'https://improxy.letiyu88.com/api/mobile/', //fun測試 proxy服
			//IMAccessCode: '2cc03acc80b3693c', //fun線上
			//IMApi: 'https://gatewayim.bbentropy.com/api/mobile/', //fun線上 proxy
			//-----BTI配置-----
			//BTIDomainId: '1hyKRxiNWPpyPjmdX19xQOIB', //fun測試 舊版(SBT)
			BTIAuthApiProxy: 'https://letiyu88.com/api/sportsdata/', //用來隱藏 bti DomainId的proxy，bti auth login/renew 用
			BTIApi: 'https://prod20078.1x2aaa.com/api/sportsdata/', //fun測試 舊版(SBT)
			BTIRougeApi: 'https://prod213.1x2aaa.com/api/rogue/', //bti新版api(目前僅支持cashout)
			BTIAnnouncements: 'https://gatewayimstaging.bbentropy.com/json_announcements.aspx', //fun測試 proxy服 用im的域名轉bti的公告

			//----沙巴配置-----
			SABAAuthApi: 'https://sabaauth.letiyu88.com/', //只有login有cors限制，其他不用
			SABAApi: 'https://apistaging.wx7777.com/',

			//是否在歐洲杯期間 直接默認開 後面需要關 再手動調
			isEUROCUP2021: false,
			SmartCoachApi: 'https://betting.stage.brainanalytics.co' //STG测试情报分析
			//SmartCoachApi: 'https://betting.dev.brainanalytics.co', //DEV情报分析
			//SmartCoachApi: 'https://api.live.smartcoach.club',	  //PRD线上情报分析
		};
	} else {
		let LiveHostApi = ''; /* 默认指向线上域名 */
		let signalRUrl = 'https://gatewayfun88wsnotification.gamealiyun.com';
		//特別處理sl環境
		if (global.location.host === 'react.fun88asia.com') {
			signalRUrl = 'https://gatewayfun88wsnotificationsl.gamealiyun.com';
		}
		/* 线上环境 */
		Config = {
			defaultSport: 'im', //默認體育頁
			isLIVE: true, //判斷在 測試/開發 還是線上環境
			DefaultDomain: global.location.origin,
			HostApi: LiveHostApi,
			LocalHost: global.location.origin + '/',
			//----mainsite app下載鏈接---
			MainSiteAppDownloadUrl: 'https://www.fun88asia.com/CN/download/app.htm',
			//-----推送配置-----
			SignalRUrl: signalRUrl,
			SportImageUrl: 'https://simg.leyouxi211.com', //聯賽隊伍圖 線上域名
			//SportImageUrl: 'https://simg.letiyu211.com',//聯賽隊伍圖 線上備用域名
			//-----緩存API配置-----
			CacheApi: 'https://sapi.leyouxi211.com', //線上域名
			//CacheApi: 'https://sapi.letiyu211.com', //線上備用域名
			//-----IM配置------
			IMAccessCode: '2cc03acc80b3693c', //fun線上
			IMApi: 'https://gatewayim.bbentropy.com/api/mobile/', //fun線上  proxy
			//备用
			// gatewayim.bbdynamic.com (backup)
			// gatewayim.bbsator.com (backup)
			//-----BTI配置-----
			//BTIDomainId: 'uGV8qqxpUKOzXZFQeWtkndHu', //fun線上
			BTIAuthApiProxy: 'https://leyouxi211.com/fun88asia/', //線上域名
			// BTIAuthApiProxy: 'https://letiyu211.com/fun88sandbox/', //線上備用域名
			BTIApi: 'https://apitw.btisports.io/fun88asia/', //fun線上
			BTIRougeApi: 'https://prod213.1x2aaa.com/api/rogue/', //bti新版api(目前僅支持cashout)
			BTIAnnouncements: 'https://gatewayim.bbentropy.com/json_announcements.aspx', //fun線上 proxy服 用im的域名轉bti的公告

			//----沙巴配置-----
			SABAAuthApi: 'https://sabaauth.leyouxi211.com/', //只有login有cors限制，其他不用
			//SABAApi: 'https://api.wx7777.com/', //沙巴 全球線 備用
			SABAApi: 'https://api.neo128.com/', //沙巴 中國專用線

			//是否在歐洲杯期間 直接默認開 後面需要關 再手動調
			isEUROCUP2021: false,
			SmartCoachApi: 'https://api.live.smartcoach.club' //线上
			//SmartCoachApi: 'https://betting.stage.brainanalytics.co' //STG测试情报分析
		};
	}
}

export default { Config };
