import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { ACTION_RouterLog_log } from '$LIB/redux/actions/RouterLogAction';
import Router from 'next/router';
import HostConfig from '$LIB/Host.config';
import { checkIsLogin, redirectToLogin } from '$LIB/js/util';

const RouteFilterContext = createContext();

function RouteFilter({ children }) {
	const { pathname, events } = useRouter();

	//允許不用登入就可以看的頁面
	const noLoginPaths = [
		'/',
		'/?*', //跳轉登入 或 帶參數
		'/login*',
		'/login/?*',
		'/sports-bti*',
		'/sports-bti/detail*',
		'/sports-im*',
		'/sports-im/detail*',
		'/sports-saba*',
		'/sports-saba/detail*',
		'/rule',
		'/search*',
		'/forgetpwd',
		'/maintenance',
		'/restrict',
		'/illegal',
		'/404',
		'/sports-bti-demo',
		'/sports-im-demo',
		'/sports-saba-demo',
		'/tutorial/handicap',
		'/tutorial/bet',
		'/newyear',
		'/laborday',
		'/event_Anniversary',
		'/share',
	];

	//測試環境才有的頁面
	const devOnlyPaths = [
		'/sports-bti-demo*',
		'/sports-im-demo*',
		'/sports-saba-demo*',
		'/w-test*', //提款測試頁 for small river
		//'/login' //開發時用的登入頁，和主站共用登入頁才使用
	];

	//需要紀錄的網址
	const historyPaths = [
		'/sports-bti?*',
		'/sports-bti/?*',
		'/sports-im?*',
		'/sports-im/?*',
		'/sports-saba?*',
		'/sports-saba/?*',
	]

	const cleanPath = (path) => {
		if (path === '/') {
			return path;
		}

		if (process.env.BASE_PATH && process.env.BASE_PATH !== '' && process.env.BASE_PATH.length > 0) {
			if (path === process.env.BASE_PATH) {
				return '/';
			}

			//去除開頭的子目錄名
			var regex = '^\\' + process.env.BASE_PATH;
			var re = new RegExp(regex, 'g');
			console.log('before replace', regex, path);
			path = path.replace(re, '');
			console.log('after replace', path);
			if (path === '' || path === '/') {
				return '/';
			}
		}

		return path.replace(/\/+$/g, ''); //去除尾巴的'/'
	};

	//支持*匹配
	const wildcardMatch = (str, rule) => {
		var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
		return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
	};
	const wildcardMatches = (str, rules) => {
		for (const thisrule of rules) {
			const thisresult = wildcardMatch(str, thisrule);
			//console.log('=====wildcardMatches',thisresult, str,thisrule);
			if (thisresult) {
				return true;
			}
		}
		return false;
	};

	const dispatch = useDispatch();

	//獲取 包含?以及其後的參數
	const getUrlParams = () => {
		if (window && window.location && window.location.href && window.location.href.indexOf('?') >= 0) {
			return window.location.href.slice(window.location.href.indexOf('?'));
		}
		return '';
	};

	useEffect(
		() => {
			// Check that a new route is OK
			const handleRouteChange = (url, isCurrentRoute = false) => {
				const isLogin = checkIsLogin();
				const cleanUrl = cleanPath(url);
				if (isCurrentRoute) {
					console.log(
						'=====App current route is : ',
						pathname,
						url,
						cleanUrl,
						wildcardMatches(cleanUrl, noLoginPaths),
						isLogin
					);
				} else {
					console.log(
						'=====App new route is : ',
						pathname,
						url,
						cleanUrl,
						wildcardMatches(cleanUrl, noLoginPaths),
						isLogin
					);
				}

				//處理默認體育頁 用replace避免 按下back按鈕 會一直卡在首頁的問題
				if (cleanUrl === '/') {
					const urlParams = getUrlParams(); //跳轉 要加上原本在/帶入的 網址參數
					const sportPagePath = '/sports-' + HostConfig.Config.defaultSport;

					//console.log('====wildcardMatches', sportPagePath)

					dispatch(ACTION_RouterLog_log(sportPagePath, sportPagePath));
					//用window.location換頁，強制觸發app-didmount(注意此處不可使用router-push)
					//用replace避免返回 / 頁面
					window.location.replace(process.env.BASE_PATH + sportPagePath + urlParams);

					//console.log('=====replace to ', HostConfig.Config.defaultSport);
					return;
				}

				//紀錄網址
				if (!isCurrentRoute && wildcardMatches(cleanUrl, historyPaths)) {
					//console.log('====wildcardMatches', pathname, url)
					dispatch(ACTION_RouterLog_log(pathname, url));
				}

				//處理特殊測試頁面
				if (HostConfig.Config.isLIVE && wildcardMatches(cleanUrl, devOnlyPaths)) {
					//console.log('=====redirect to 404',HostConfig.Config.isLIVE);
					//TODO: 如果404頁和主站共用，可以修改此路徑
					window.location.href = process.env.BASE_PATH + '/404';
					throw { cancelled: true }; //丟出例外 取消當前跳轉某頁的動作
				}

				//處理登入態檢查
				if (!wildcardMatches(cleanUrl, noLoginPaths) && !isLogin) {
					//console.log('=====redirect to login', isLogin);
					const urlParams = getUrlParams(); //跳轉 要加上原本在/帶入的 網址參數
					if (isCurrentRoute) {
						redirectToLogin(urlParams, true);
					} else {
						redirectToLogin(urlParams, false);
						throw { cancelled: true }; //丟出例外 取消當前跳轉某頁的動作
					}
				}
			};

			handleRouteChange(pathname, true);

			const isLogin = checkIsLogin();
			if (isLogin) {
				const loginOtp = localStorage.getItem('LoginOTP');
				const revalidate = localStorage.getItem('Revalidate');
				const mini = localStorage.getItem('miniurlopen');

				//TODO: 如果登入頁和主站共用，可能需要另外處理這個狀況
				if (revalidate == 'true' && revalidate != 'undefined') {
					Router.push('/verify/?type=resetpwd');
				} else if (loginOtp == 'true' && loginOtp != 'undefined') {
					Router.push('/verify/?type=loginOTP');
				}
			}

			// Monitor routes
			events.on('beforeHistoryChange', handleRouteChange); //用beforeHistoryChange才支持丟出例外 取消跳轉
			return () => {
				events.off('beforeHistoryChange', handleRouteChange);
			};
		},
		[ pathname ]
	);

	return <RouteFilterContext.Provider value=''>{children}</RouteFilterContext.Provider>;
}

const useRouteFilter = () => useContext(RouteFilterContext);

export { RouteFilter, useRouteFilter };
