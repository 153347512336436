/*
 * @Author: Alan.wu
 * @Date: 2019-11-19 17:42:28
 * @Last Modified by: alan.wu
 * @Last Modified time: 2019-12-15 10:38:37
 */
import HostConfig from './Host.config';
import Toast from '@/Toast';
import Router from 'next/router';
import {checkIsLogin, setIsLogout, redirectToLogin} from "./js/util";

const HOST = HostConfig.Config.HostApi;
// const Apiversion = 'api-version=2.0&brand=tlc&Platform=Mobile';
const Apiversion = 'api-version=1.0&brand=FUN88&siteId=31&Platform=Mobile';

export const fetchRequest = (url, method, params = '', withAuth = false, timeout = 600000) => {
	let header;
	if (localStorage.getItem('memberToken') &&
		(
			checkIsLogin()
		  || withAuth //特例refresh token 和 getMemberData不需要檢查已登入 for url token 登入
		)
	){
		header = {
			'Content-Type': 'application/json; charset=utf-8',
			'Culture': 'zh-cn',
			'token': 'rate',
			'Authorization': JSON.parse(localStorage.getItem('memberToken'))
		};
	} else {
		header = {
			'Content-Type': 'application/json; charset=utf-8',
			'Culture': 'zh-cn',
			'token': 'rate',
		};
	}
	return new Promise(function(resolve, reject) {
			let fetchInitData = {
				method: method,
				headers: header,
			}

			if (params !== '') {
				fetchInitData.body = JSON.stringify(params);
			}

			const apiUrl = url + Apiversion;

			timeout_fetch(
				fetch(apiUrl, fetchInitData),
				timeout
			)
				.then((response) => response.json())
				.then((responseData) => {
					if (responseData.redirect) {
						var searchURL = window.location.hash;
						searchURL = searchURL.substring(1, searchURL.length);
						var targetPageId = searchURL.split('&')[0].split('=')[1];
						if (responseData.redirect.Code == 'GEN0003') {
							if (targetPageId) {
								window.location.href = responseData.redirect.Message + '?aff=' + targetPageId;
							} else {
								window.location.href = responseData.redirect.Message;
							}
						}
					}
					if (responseData.error_details) {
						if(global.isAgainLogin){
							global.error_details=responseData.error_details;
							if(global.openLoginFailModal&&typeof global.openLoginFailModal==='function'){
								global.openLoginFailModal();
								global.isAgainLogin=false;
								responseData.json();
								return;
							}
						}
						const ERRORCODE = responseData.error_details.Code;
						switch (ERRORCODE) {
							case 'MEM00145':
                // Globelogout();
								global.HttpStatus = -100;
								Router.push('/restrict');
								break;
							case 'MEM00061':
								Toast.error('您的帐号无法使用,请联系在线客服!', 2);
								break;
							case 'GEN0002':
								/* 地区访问限制 */
								global.HttpStatus = -100;
								Router.push('/restrict');
								break;
							case 'GEN0001':
								window.location.assign('/static/Maintenance.html');
								break;
							case 'GEN0006':
								Toast.error('请重新登录,访问过期', 3);
								setIsLogout();
								redirectToLogin();
								break;
							case 'GEN0005':
								if (checkIsLogin()) {
									Toast.error('重复登录,系统检测到您重复登录', 2);
									setIsLogout();
									redirectToLogin();
								}
								break;
							default:
								if (responseData.error_details.Message || responseData.error_details.Description != '') {
									Toast.error(responseData.error_details.Message || responseData.error_details.Description, 2);
								}
								if (
									responseData.error_details.Code == 'MEM00140' ||
									responseData.error_details.Code == 'MEM00060' ||
									responseData.error_details.Code == 'MEM00059' ||
									responseData.error_details.Code == 'MEM00004' ||
									responseData.error_details.Code == 'CAPTCHA_ERROR'
								) {
									/* 抛出错误 用于极验 */
									responseData.json();
								}
						}
					} else {
						resolve(responseData);
					}
				})
				.catch((err) => {
					// 网络请求失败返回的数据
					console.log('fetchRequest err:', apiUrl , JSON.stringify(params) , JSON.stringify(err));
					reject(err);
				});
		});
};

export const timeout_fetch = (fetch_promise, timeout = 600000) => {
	let timeout_fn = null;
	let timeout_promise = new Promise(function(resolve, reject) {
		timeout_fn = function() {
			reject('请求超时!!!');
		};
	});
	let abortable_promise = Promise.race([ fetch_promise, timeout_promise ]);
	setTimeout(function() {
		timeout_fn();
	}, timeout);

	return abortable_promise;
}
